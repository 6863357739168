import {useContext, useEffect, useRef, useState} from "react";
import {Button, MenuItem, Select} from "@mui/material";
import {Instruction} from "../../../api/WriterAPI";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {InstructionRow, InstructionWithUuid} from "./InstructionRow";

export function InstructionList(props: {
    value: InstructionWithUuid[]
    onChange(value: InstructionWithUuid[]): void
    right?: any
}) {
    const [list, setList] = useState<InstructionWithUuid[]>(props.value)

    useEffect(() => {
        const arr = props.value || []
        if(arr.length === 0) {
            arr.push({
                type: "login",
                value: "",
                uuid: window.crypto.randomUUID(),
            })

            arr.push({
                type: "step",
                value: "",
                uuid: window.crypto.randomUUID(),
            })
        }

        setList(arr)
    }, [props.value])

    const lastMod = useRef<null|number>(null)

    const onChangeRef = useRef(props.onChange)
    onChangeRef.current = props.onChange;

    const listRef = useRef(list)
    listRef.current = list;

    useEffect(() => {
        const watch = setInterval(() => {
            if(!lastMod.current) return;
            if(Date.now() - lastMod.current < 200) return;
            lastMod.current = null;
            onChangeRef.current(listRef.current)
        }, 200)

        return () => clearInterval(watch)
    }, []);

    const [autoFocus, setAutoFocus] = useState<number|null>(null)

    return (
        <DndProvider backend={HTML5Backend}>
            <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                {list.map((ins, index) => {
                    return (<InstructionRow
                        key={ins.uuid}
                        index={index}
                        value={ins}
                        autoFocus={autoFocus === index}
                        onFocusClear={() => {
                            setAutoFocus(null)
                        }}
                        onFocusNext={() => {
                            if(index === list.length-1) {
                                const l2 = list.slice(0);
                                l2.push({
                                    type: "step",
                                    value: "",
                                    uuid: window.crypto.randomUUID(),
                                })

                                setList(l2)
                                lastMod.current = Date.now()
                            }

                            setAutoFocus(index+1)
                        }}
                        onChange={(index, value) => {
                            list[index] = value;
                            setList(list.slice(0))
                            lastMod.current = Date.now()
                        }}
                        reOrder={(from, to) => {
                            const item = list[from]
                            list.splice(from, 1)
                            list.splice(to, 0, item)
                            setList(list.slice(0))
                            lastMod.current = Date.now()
                        }}
                        onRemove={() => {
                            list.splice(index, 1)
                            setList(list.slice(0))
                            lastMod.current = Date.now()
                        }}
                    />)
                })}

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingTop: 8,
                }}>
                    <Button size="small" onClick={() => {
                        setList(list.concat([{
                            type: "step",
                            value: "",
                            uuid: window.crypto.randomUUID(),
                        }]))
                        lastMod.current = Date.now()
                    }}>Add Step</Button>

                    {props.right}
                </div>
            </div>
        </DndProvider>
    )
}


function isBlankInstruction(input: InstructionWithUuid) {
    // @ts-ignore
    if(input.type === "step" || input.type === "") {
        return input.value === ""
    }

    return false
}

function ensure1Blank(arr: InstructionWithUuid[]) {
    while(arr.length >= 1 && isBlankInstruction(arr[arr.length-1])) {
        arr.splice(arr.length-1, 1)
    }

    arr.push({
        type: "step",
        value: "",
        uuid: window.crypto.randomUUID(),
    })
}