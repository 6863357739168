import {APIBase} from "nate-react-api-helpers";

export type FileNode = {
    id: number;
    name: string;
    nodeType: "file" | "folder"
    parent?: number;
    archived?: boolean
    sortOrder: number;

    compileStatus?: TaskStatus
    specialInstructions: Instruction[];
}

export type Instruction = {
    type: "step" | "login" | "runFile" | "verify"
    value: string;
    runFile?: number;
}

export type FileDetail = {
    id: number;
    name: string;
    instructions: Instruction[];
    lastTask?: number;
    currentTask?: number;
    compiledInstructions?: CompiledStep[]
    taskStatus?: TaskStatus
    compileStatus?: TaskStatus
    taskBuildImages?: number[];
    dir: number;

    debugLog: DebugLogItem[]
}

export type DebugLogItem = {
    Timestamp: DateString;
    Url: string;
    Form: string;
    Click: string;
    UserChunk?: string;
    UserError?: string;
    S3ScreenshotId?: number;
}

export type TaskStatus = "pending" | "failed" | "cancelled" | "success"

export type DateString = string;

export type CompiledStep = {
    id: number;
    type: CompiledStepType; // testfile.Type
    description: string;
}

export type CompiledStepType = 'verify' | 'verify-data' | 'fill' | 'click' | 'click-data' | 'nav'

export class SubAPI {
    parent: APIBase;

    get fetcher() {
        return this.parent.fetcher
    }

    constructor(parent: APIBase) {
        this.parent = parent;
    }
}

export class WriterAPI extends SubAPI {
    listNodes() {
        return this.fetcher.get<FileNode[]>("/api/writer/nodes")
    }

    upsertNode(input: FileNode) {
        return this.fetcher.post<FileNode>("/api/writer/node", input)
    }

    getFileDetail(input: {
        node: number
    }) {
        return this.fetcher.get<FileDetail>("/api/writer/node/file-detail", input)
    }

    upsertFile(input: FileDetail) {
        return this.fetcher.post("/api/writer/node/file-detail", input)
    }

    compileInstructions(input: {
        file: number,
        cancel?: true
    }) {
        return this.fetcher.post("/api/writer/node/compile-file", input)
    }

    compileProgress(input: {
        since: number;
    }) {
        return this.fetcher.get<ProgressItem[]>("/api/writer/node/compile-progress", input)
    }

    getProjectConfig() {
        return this.fetcher.get<ProjectConfig>("/api/writer/project-config")
    }

    updateProjectConfig(input: ProjectConfig) {
        return this.fetcher.post("/api/writer/project-config", input)
    }

    listUdt() {
        return this.fetcher.get<UDT[]>("/api/writer/udt")
    }

    uploadUdt(input: {
        value: UDT,
        file: File | Blob
    }) {
        return this.fetcher.postFormData("/api/writer/udt/upload", {
            value: JSON.stringify(input.value),
            file: input.file
        })
    }

    upsertUdt(input: UDT) {
        return this.fetcher.post("/api/writer/udt", input)
    }

    downloadUdt(input: {
        id: number;
    }) {
        return this.fetcher.get<Response>("/api/writer/udt/download", input)
    }
}

export type UDT = {
    id: number;
    name: string;
    internalTag: "login" | "";
    csvFile: number // s3file
    archived: boolean;
}

export type ProjectConfig = {
    startUrl: string
}

type ProgressItem = {
    id: number;
    name: string;
    isActive: boolean;
}