import {css} from "@emotion/css";
import {InstructionWithUuid} from "./InstructionRow";
import {useState} from "react";
import {LoginsInner, useLogins} from "../rootPage/Logins";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";

export function LoginPicker(props: {
    value: InstructionWithUuid;
    onChange(value: InstructionWithUuid): void;
}) {

    const login = props.value.value;
    const logins = useLogins();

    const data = logins.result?.data;

    const missing = login !== "" && data && !data.find(d => d[0] === login);

    const selValue = missing ? "missing" : logins.loading ? "loading" :
        login === "" || login === "-" ? "-" : login;

    const [showAddEdit, setShowAddEdit] = useState(false);

    return (
        <>
            {showAddEdit && <Dialog open>
                <DialogTitle>
                    Add/Edit Logins
                </DialogTitle>
                <DialogContent>
                    <LoginsInner onUpdate={() => {
                        setShowAddEdit(false);
                        logins.reload()
                    }} />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => {
                        setShowAddEdit(false);
                    }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>}
            <select className={inputCss} value={selValue} onChange={e => {
                const val =e.target.value;
                if(val === "-") {
                    props.onChange({...props.value, value: ""});
                } else if(val === "add/edit") {
                    setShowAddEdit(true);
                } else if(val === "missing" || val === "loading") {
                    return;
                } else {
                    props.onChange({...props.value, value: val});
                }
            }}>
                <option value="-">Choose Login</option>
                {selValue === "loading" && <option value="loading">Loading...</option>}
                {missing && <option value="missing">Error: missing options {login}</option>}
                {data?.map((row, index) => {
                    return <option key={index} value={row[0]}>{row[0]}</option>
                })}
                <option value="add/edit">Add/Edit Logins</option>
            </select>
            {logins.loading && <div><CircularProgress size={10} /></div>}
            {logins.error ? logins.LoadingOrErrorElement : null}
        </>
    )
}

const inputCss = css({
    border: "none",
    padding: 8,
    paddingLeft: 4,
    fontSize: "0.8rem",
    flex: 1
})