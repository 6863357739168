import {deepOrange, grey} from "@mui/material/colors";
import {FileDetail} from "../../../api/WriterAPI";
import React, {useRef} from "react";
import {Alert, Button, Grid, LinearProgress, Typography} from "@mui/material";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {loadCompileProgressEmitter} from "../sidebar/CompileProgress";
import {TaskStatusIndicator} from "./TaskStatusIndicator";
import {OpenInNew} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import { CompiledInstructions } from "./CompiledInstructions";
import {DebugLog} from "./DebugLog";

export function BuildInfo(props: {
    value: FileDetail | null
    instructionsChanged: boolean;
    onUpdate(): void;
}) {
    const f = props.value;
    const loading = f === null ? null : !!f.currentTask && f?.taskStatus === "pending";
    const needsInstructions = props.value?.instructions.length === 0 || props.instructionsChanged
    const onUpdateRef = useRef(props.onUpdate)
    onUpdateRef.current = props.onUpdate;

    const compile = useAsyncAction(async (input) => {
        await api.writer.compileInstructions(input)
        onUpdateRef.current();
        loadCompileProgressEmitter.emit(true);
    }, []);

    const hasSuccess = props.value?.compileStatus === "success";
    const nav = useNavigate()

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item>
                    <Typography variant={"h6"}>
                        Test Robot
                    </Typography>
                    <div>
                        {loading ? <LinearProgress /> : null}
                    </div>
                    <div>
                        {(f && f.compileStatus) ? <TaskStatusIndicator value={f.taskStatus ==="failed" ? f.taskStatus : f.compileStatus} /> : null}
                    </div>
                </Grid>
                {hasSuccess && <Grid item xs />}
                {hasSuccess && <Grid item>
                    <Button
                        variant="outlined"
                        size="small"
                        disabled={needsInstructions || !!loading}
                        startIcon={<OpenInNew />}
                        onClick={() => {
                            nav("/runs/new")
                        }}>
                        Run Test Suite
                    </Button>
                </Grid>}
                <Grid item>
                    <Button
                        variant="outlined"
                        size="small"
                        disabled={needsInstructions || !!loading}
                        onClick={() => {
                            compile.callback({
                                file: f?.id || 0
                            })
                        }}>{hasSuccess ? "Reload Instructions" : "Load Instructions"}</Button>
                    {(compile.loading || compile.error) && compile.LoadingElement}
                </Grid>
                {loading && <Grid item>
                    <Button variant="outlined" size="small" onClick={() => {
                        compile.callback({
                            file: f?.id || 0,
                            cancel: true,
                        })
                    }}>Cancel Load</Button>
                </Grid>}
                <Grid item>
                </Grid>
            </Grid>

            <div style={{
                width: "100%",
                border: "1px solid " + grey["300"],
                padding: 8,
                marginTop: 8,
            }}>
                {!loading && needsInstructions &&
                    <Typography variant={"body2"} color={deepOrange["700"]}>
                        Create and save your instructions first.
                    </Typography>}

                {f && !f?.taskStatus && <>
                    <Typography variant={"body2"}>
                        Click "Load Instructions" to train the Test Robot on this task
                    </Typography>
                </>}
                {f?.taskStatus === "failed" && <Alert severity="error">
                    Failed to load instructions. Here are a few things to check:
                    <div>
                    <ol style={{paddingLeft: 18}}>
                        <li>Make sure your instructions can't be confused with other things on the page.</li>
                        <li>Sometimes simply running the "Load Instructions" again will fix the issue</li>
                    </ol>
                    </div>
                </Alert>}

                {(loading || !f?.compiledInstructions) ?
                    <DebugLog value={f?.debugLog} /> :
                    <CompiledInstructions value={f?.compiledInstructions} />
                }
            </div>
        </div>
    )
}