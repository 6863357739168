import {Accordion, AccordionDetails, AccordionSummary, Alert, Button, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {css} from "@emotion/css";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {useEffect, useState} from "react";
import VirtualizedTable from "./EditTable";
import {UDT} from "../../../api/WriterAPI";

function last<T>(input: T[]) {
    if(input.length === 0) return null;
    return input[input.length - 1];
}

export function useLogins() {
    return useAsync2(async () => {
        const files = await api.writer.listUdt()
        const loginFile = last(files.filter(f => f.internalTag === "login"))
        if(!loginFile) return null;

        const req = await api.writer.downloadUdt({id: loginFile.id})
        const csv = await req.text();
        if(req.headers.get("content-type")?.indexOf("text/csv") === -1) throw new Error(csv)

        return {
            file: loginFile,
            data: csvDecode(csv),
        }
    }, {}, []);
}

export function Logins() {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                    Logins
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <LoginsInner />
            </AccordionDetails>
        </Accordion>
    )
}

export function LoginsInner(props: {
    onUpdate?(): void;
}) {
    const file = useLogins()

    const update = useAsyncAction(async (input: {
        file?: UDT
        data: string[][]
    }) => {
        const csvData = csvEncode(input.data);

        await api.writer.uploadUdt({
            value: input.file || {
                id: 0,
                name: "Logins",
                internalTag: "login",
                csvFile: 0,
                archived: false,
            },
            file: new Blob([csvData], {type: "text/csv"}),
        })

        if(props.onUpdate) {
            props.onUpdate();
        }

        file.reload();
    }, [props.onUpdate]);

    const [table, setTable] = useState<string[][]>([]);

    const rs = file.result?.data;
    useEffect(() => {
        if(rs) {
            setTable(rs);
        }
    }, [rs]);

    useEffect(() => {
        if(table.length === 0) {
            setTable([["", "", ""]]);
        }
    }, [table]);

    return (
        <form onSubmit={e => {
            e.preventDefault();
            update.callback({
                file: file.result?.file,
                data: table,
            })
        }}>
            <div style={{
                marginLeft: -16, marginRight: -16, overflow: 'auto', minHeight: 300,
                display: "flex", flexDirection: "column",
            }}>
                <Alert severity="info">
                    This is a central spot to manage your test logins. <br/>
                    The "Name/Label" is how you'll refer to this login in your tests.
                </Alert>

                {file.LoadingOrErrorElement}

                <div style={{height: 16}}/>
                <VirtualizedTable
                    columns={["Name/Label", "Username", "Password"]}
                    customizeColumn={(input) => {
                        if(input.name === "Password") {
                            input.type = "password";
                        }
                    }}
                    value={table}
                    onChange={setTable}
                    onAddBlank={() => {
                        setTable([...table, ["", "", ""]])
                    }}
                />
                <div style={{height: 16}}/>

            </div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                {update.LoadingElement}
                <Button variant="outlined" size="small" type="submit">Save</Button>
            </div>
        </form>
    )
}

export function csvEncode(csv: string[][]): string {
    return csv.map(row =>
        row.map(cell => {
            // Check if the cell contains special characters
            const needsEscaping = /["\n,]/.test(cell);
            return needsEscaping
                ? `"${cell.replace(/"/g, '""')}"`  // Escape double quotes and wrap in quotes if needed
                : cell;  // Return the cell as-is if no escaping is needed
        }).join(',') // Join cells in the row with commas
    ).join('\n'); // Join rows with new lines
}

export function csvDecode(csv: string): string[][] {
    const result: string[][] = [];
    let currentRow: string[] = [];
    let currentValue = '';
    let inQuotes = false;

    for (let i = 0; i < csv.length; i++) {
        const char = csv[i];

        if (char === '"' && (i === 0 || csv[i - 1] !== '\\')) { // Start or end of quoted value
            inQuotes = !inQuotes; // Toggle the inQuotes flag
            continue;
        }

        // If we're inside quotes, we need to handle escaped quotes
        if (inQuotes) {
            if (char === '"') {
                // Check for an escaped quote
                if (i + 1 < csv.length && csv[i + 1] === '"') {
                    currentValue += '"';
                    i++; // Skip the next quote
                } else {
                    currentValue += char; // Just add the quote if not escaped
                }
            } else {
                currentValue += char; // Accumulate characters in quotes
            }
        } else {
            if (char === ',') {
                // End of a value
                currentRow.push(currentValue.trim());
                currentValue = ''; // Reset for the next value
            } else if (char === '\n') {
                // End of a row
                currentRow.push(currentValue.trim());
                result.push(currentRow);
                currentRow = []; // Reset for the next row
                currentValue = ''; // Reset for the next value
            } else {
                currentValue += char; // Accumulate characters outside quotes
            }
        }
    }

    // Push the last value if there's remaining data
    if (currentValue || currentRow.length) {
        currentRow.push(currentValue.trim());
        result.push(currentRow);
    }

    return result;
}