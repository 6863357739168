import {ListItem, Typography} from "@mui/material";
import React from "react";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import moment from "moment";
import {SuccessChip} from "../../runs/runDetail/SuccessChip";
import {dateFormat} from "../../runs/RunList";

export function PreviousRuns(props: {
    id?: number;
}) {

    const list = useAsync2(async (input) => {
        const file = input.file;
        if(!file) return null;
        return api.runner.getHistorical({
            file,
        })
    }, {
        file: props.id,
    }, [props.id]);

    return (
        <div>
            <Typography variant={"h6"}>
                Recent Runs
            </Typography>
            <div>
                {list.asList?.map((h, index) => (
                    <ListItem key={h.runItem.toString()} style={{
                        background: index %2 === 0 ? "hsla(0,0%,0%,0.05)" : "",
                    }}>
                        <a style={{flex: 1}} href={`/runs/${h.companySeqNum}?v=${h.runItem}`}>
                            <Typography variant="body2">
                                #{h.companySeqNum} - {moment(h.timestamp).format(dateFormat)}
                            </Typography>
                        </a>


                        <Typography variant="body2">
                            {moment.duration(h.durationMs, "ms").asSeconds()}s
                        </Typography>

                        <div style={{width: 16}} />
                        <div style={{minWidth: 100}}>
                            <SuccessChip status={h.status} />
                        </div>
                    </ListItem>))}
            </div>
        </div>
    )
}