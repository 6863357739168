import {CompiledStep} from "../../../api/WriterAPI";
import {Accordion, AccordionDetails, AccordionSummary, capitalize} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function CompiledInstructions(props: {
    value: CompiledStep[] | null | undefined
}) {
    return (
        <div>
            {props.value?.map(v => {
                return (
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {capitalize(v.type)}
                        </AccordionSummary>
                        <AccordionDetails>
                            {v.description}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    )
}

